import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';

export default {

    extends: baseComponent,

    props: {
        element: {
            type: Object,
            default: null
        },
    },

    data() {
        return {
            confirmText: '',
            request: {
                ip: '',
                ipDetails: '',
                type: 30000,
                description: '',
                action: {
                    userRegister: false,
                    userLogin: false,
                    userForgotPassword: false,
                    userResentActiveEmail: false,
                    userChangeProfile: false,
                    userChangePassword: false,
                    imageCreate: false,
                    imageBoot: false,
                    imageShutdown: false,
                    imageConnect: false,
                    imageClone: false,
                    imageDelete: false,
                    imageChangeServicePackage: false,
                    imageRename: false,
                },
                deviceInformation: {}
            },
            listType: [],
        };
    },

    computed: {

    },

    created() {
        this.$restrictedIpAddressStatus.forEach(element => {
            if (this.listType.find(x => x.label === element.name) === undefined) {
                this.listType.push({
                    label: element.name,
                    value: element.status,
                })
            }
        });
    },

    mounted() {

    },

    methods: {

        // Selected type
        selectedType() {
            this.request.action = this.$restrictedIpAddressStatus.find(x => x.status === this.request.type).action;
            this.request.description = this.$restrictedIpAddressStatus.find(x => x.status === this.request.type).reason;
        },

        // Close this pop-up
        closeThisPopup() {
            this.listData = {};
            this.closePopup('user-history-add-restricted-list');
        },

        // Add
        add() {
            this.isLoading = true;
            this.request.ip = this.element.ip;
            this.request.ipDetails = JSON.stringify(this.element.ipDetails);
            this.request.deviceInformation = JSON.stringify(this.getInformationClient());
            authApi.systemRetrictedIpAddressAdd(this.request)
                .then((res) => {
                    try {
                        this.showSuccessToast(`Successful add IP address to restricted list !`);
                        setTimeout(() => {
                            this.closePopup('user-history-add-restricted-list');
                        }, 500);
                    } catch (error) {
                        console.log("changeSettingsUser -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            console.log("changeSettingsUser -> error", error)
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                });
        }
    }
} 